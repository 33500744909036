/**
 * 与老师有关的接口
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class TeacherApi {
    // 老师列表
    static index(params) {
        return request({
            url: '/teacher/index',
            method: 'post',
			data: params
        })
    }

    // 老师详情
    static info(params) {
        return request({
            url: '/teacher/info',
            method: 'post',
			data: params
        })
    }

    // 修改个人资料
    static store(params) {
        return request({
            url: '/teacher/store',
            method: 'post',
			data: params
        })
    }

    // 老师文凭接口
    static qualifications(method, params) {
        let r = {
            url: '/teacher/qualifications',
            method: method || 'GET'
        }
        if(r.method.toUpperCase() == 'GET') {
            r.params = params
        } else {
            r.data = params
        }
        return request(r)
    }

    // 个人认证信息
    static identification(method, params) {
        return request({
            url: '/teacher/identification',
            method: method || 'get',
			data: params
        })
    }

    // 支付数据
    static paymentdetail(method, params) {
        return request({
            url: '/teacher/paymentdetail',
            method: method || 'get',
			data: params
        })
    }

    // 时间安排
    static availability(method, params) {
        return request({
            url: '/teacher/availability',
            method: method || 'get',
			data: params
        })
    }

    // 时间
    static zoneAvailability(params) {
        return request({
            url: '/teacher/zone-availability',
            method: 'post',
            data: params
        })
    }

    // 老师列表
    static list(params) {
        return request({
            url: '/teacher/list',
            method: 'post',
            data: params
        })
    }
}