<template>
  <div class="set-locale">
      <div v-if="type === 'button'">
            <el-button type="info" @click="changeLocale('zh-cn')">简体中文</el-button>
            <el-button v-if="isTutoredin" type="info" @click="changeLocale('en')">English</el-button>
            <el-button v-if="isChineseBigBang" type="info" @click="changeLocale('ru')">Russia</el-button>
      </div>
      <div v-else :style="parseStyle">
            <div class="locale-link">
                <el-link :class="parseClass('zh-cn')" @click="changeLocale('zh-cn')">简体中文</el-link>
            </div>
            <div class="locale-link" v-if="isTutoredin">
                <el-link :class="parseClass('en')" @click="changeLocale('en')">English</el-link>
            </div>
            <div class="locale-link" v-if="isChineseBigBang">
                <el-link :class="parseClass('ru')" @click="changeLocale('ru')">Russia</el-link>
            </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import { setDocuemntTitle } from '@/utils/index.js'
import { useI18n } from 'vue-i18n'
import Config from '@/utils/config'

export default defineComponent({
  name: 'SetLocales',
  setup() {
    const { locale } = useI18n()
    function changeLocale(lang) {
      locale.value = lang;
      this.$store.dispatch('setLang', locale.value);
      setDocuemntTitle(this.$route.meta, locale.value);
    }
    return { changeLocale }
  },
  data() {
    return {
        // lang: storage.get('lang')
    }
  },
  props: {
    type: {
        type: String,
        default: 'link'
    },
    color: {
        type: String,
        default: '#FFFFFF'
    },
    hoverColor: {
        type: String,
        default: ''
    }
  },
  computed: {
    ...mapGetters([
        'lang'
    ]),
    isTutoredin() {
      return Config.isTutoredin();
    },
    isChineseBigBang() {
      return Config.isChineseBigBang();
    },
    parseStyle() {
        let obj = {};
        obj.color = this.color || "#FFFFFF";
        obj['--color'] =  obj.color;
        obj['--hover-color'] =  this.hoverColor || '#f56c6c';
        return obj;
    }
  },
  methods: {
    parseClass(value) {
        let arr = ['link'];
        let lang = this.lang || 'zh-cn';
        if(value == lang) {
            arr.push('on');
        }
        return arr;
    }
  }
})
</script>
<style lang="less">
    .set-locale {
        .locale-link {
            display: inline-block;

            .link {
                font-weight: 600;
                color: var(--color);

                &:hover {
                    color: var(--hover-color);
                }

                &.on {
                    color: var(--hover-color);
                }
            }

            &:not(:last-child)::after {
                content: '';
                border-right: 1px solid var(--color);
                // height: 15px;
                margin: 0 10px;
            }
        }
    }
</style>