import { getStorage, setStorage, removeStorage } from '@/utils/index.js'
import store from '@/store'
import FaqsApi from '@/api/faqs'

const faqs = {
  state: {
  	menu: []
  },
  mutations: {
    SET_FAQMENU_DATA: (state, data) => {
    	state.menu = data
    }
  },
  actions: {
    GetFaqsMenu ({ commit }) {
      return new Promise((resolve, reject) => {
        FaqsApi.category().then(response => {
          const data = response.data
          commit('SET_FAQMENU_DATA', data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default faqs