<template>
	<el-footer>
		<div class="footer-two">
			<div class="logo">Tutoredin</div>
			<div class="company" v-if="webSiteData.company_fullname">{{ webSiteData.company_fullname }}</div>
			<el-row :gutter="20">
				<el-col :span="6">
					<!-- <div class="text item">
						<el-link :underline="underline">{{ $t('Platform Blog') }}</el-link>
					</div> -->
					<div class="text item">
						<el-link :underline="underline" @click="$router.push('/faqs')">{{ $t('Faqs') }}</el-link>
					</div>
					<!-- <div class="text item">
						<el-link :underline="underline">{{ $t('Public welfare plan') }}</el-link>
					</div> -->
					<div class="text item">
						<el-link :underline="underline" @click="$router.push('/contact-us')">Contact us</el-link>
					</div>
				</el-col>
				<el-col :span="6">
					<!-- <div class="text item">
						<el-link :underline="underline">{{ $t('About Us') }}</el-link>
					</div>
					<div class="text item">
						<el-link :underline="underline">{{ $t('Suggestions') }}</el-link>
					</div>
					<div class="text item">
						<el-link :underline="underline">{{ $t('Terms of use') }}</el-link>
					</div>
					<div class="text item">
						<el-link :underline="underline">{{ $t('Privacy Agreement') }}</el-link>
					</div> -->
				</el-col>
				<el-col :span="6">
					<div class="text item contract" v-if="!!webSiteData.email">
						<el-icon><i class="fa fa-envelope"></i></el-icon>
						<div><el-link :underline="underline">{{ webSiteData.email }}</el-link></div>
					</div>
					<div class="text item contract" v-if="!!webSiteData.phone_86">
						<el-icon><i class="fa fa-phone"></i></el-icon>
						<div><el-link :underline="underline"><span>{{ $t('china') }}</span>：{{ webSiteData.phone_86 }}</el-link></div>
					</div>
					<div class="text item contract" v-if="!!webSiteData.phone_44">
						<el-icon><i class="fa fa-phone"></i></el-icon>
						<div><el-link :underline="underline"><span>{{ $t('britain') }}</span>：{{ webSiteData.phone_44 }}</el-link></div>
					</div>
					<div class="text item contract" v-if="!!webSiteData.phone_7">
						<el-icon><i class="fa fa-phone"></i></el-icon>
						<div><el-link :underline="underline"><span>{{ $t('russia') }}</span>：{{ webSiteData.phone_7 }}</el-link></div>
					</div>
				</el-col>
				<el-col :span="6">
					<el-space wrap v-if="!!qrcodeList && qrcodeList.length > 0">
						<template v-for="item, index in qrcodeList">
							<div class="text item third" v-if="item">
								<el-image
									style="width: 100px; height: 100px"
									:src="item.url"
									fit="fill"
								></el-image>
								<div class="tu-link"><el-link :underline="underline">{{ item.title }}</el-link></div>
							</div>
						</template>
					</el-space>
				</el-col>
			</el-row>
		</div>
		<div class="footer-bar">
			<div class="bottombar">
				<div>
					<el-space :size="size" :spacer="spacer">
						<div>Copyright © 2020-{{ fullYear }} Tutoredin</div>
						<div v-if="webSiteData.company_fullname">{{ webSiteData.company_fullname }}</div>
						<div style="display: flex;gap: 10px;">
							<el-link v-if="gongan" href="https://beian.mps.gov.cn/#/query/webSearch?code=11010102007105" rel="noreferrer" target="_blank">
								<!-- <el-image style="width: 20px; height: auto" :src="require('@/assets/gongan.png')" fit="fill"></el-image>  -->
								<div style="color: var(--el-color-white);">{{ gongan }}</div>
							</el-link>
							<el-link v-if="beian" href="https://beian.miit.gov.cn/" style="color: var(--el-color-white)" target="_blank">
								{{ beian }}
							</el-link>
						</div>
					</el-space>
				</div>
				<div>
					<SetLocales type="link"></SetLocales>
				</div>
			</div>
		</div>
	</el-footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { h, resolveComponent } from 'vue'
import { ElDivider } from 'element-plus'
import timeControl from '@/utils/time-control'
import SetLocales from '@/components/SetLocales'
import Config from '@/utils/config'

export default {
	name: 'Footer',
	components: {
		SetLocales
	},
	computed: {
		...mapGetters([
			'webSiteData', 'userType'
		]),
		beian() {
			return Config.website('beian');
		},
		gongan() {
			return Config.website('gongan');
		}
	},
	data() {
		return {
			fullYear: 2022,
			size: 4,
			spacer: h(ElDivider, { direction: 'vertical' }),
			underline: false,
			qrcodeList: []
		}
	},
	created() {
		this.fullYear = timeControl.getFullYear()
		this.$store.dispatch('GetWebSite')
	},
	watch: {
		webSiteData() {
			this.handleWebSiteData()
		}
	},
	methods: {
		handleWebSiteData() {
			if(!!this.webSiteData) {
				let qrcode = this.webSiteData['qrcode'] || {};
				if(!!qrcode) {
					this.qrcodeList = [];
					for(let i in qrcode) {
						if(!qrcode[i]) {
							continue;
						}
						let arr = qrcode[i].split('#')
						if (arr.length == 2) {
							this.qrcodeList.push({url: arr[1], title: arr[0]})
						} else {
							this.qrcodeList.push({url: arr[0], title: '二维码'})
						}
					}
				}
			}
		}
	}
}
</script>
<style lang="less">
.el-footer {
	color: #9d9d9d;
  // background: var(--el-color-white);
  background: var(--el-color-info-lighter);
  border-top: 1px solid #e3e9ed;
  margin: 48px 0 0 0;
  padding: 0 !important;
  font-size: 14px;
  height: auto !important;

  .footer-two {
  	width: var(--tu-main-width);
  	margin: 0 auto;
  	padding: 20px 0;
  	color: inherit;

  	.logo {
  		line-height: 32px;
    	font-size: 32px;
  		text-align: left;
  		margin: 3px 0;
  		color: var(--el-color-primary);
    	font-weight: 600;
  	}

  	.company {
  		font-size: 16px;
  		margin: 8px 0 14px 0;
  		text-align: left;
  		color: #7a7a7a;
  		font-weight: 400;
  	}

  	.item {
  		text-align: left;
  		line-height: 2.2;
  	}

  	.contract {
  		display: flex;
  		flex-direction: row;
  		align-items: center;

  		.el-icon {
  			font-size: 18px;
  			color: orange;
  		}

  		div {
  			margin-left: 8px;
  		}
  	}

  	.third {
  		text-align: center;

  		.tu-link {
  			line-height: 0.8;
  		}
  	}
  }
	
	.footer-bar {
		background: var(--el-color-primary);
		color: var(--el-color-white);

		.bottombar {
			width: var(--tu-main-width);
			margin: 0 auto;
			line-height: 42px;			
			padding: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
}
</style>