import { ElMessage } from 'element-plus'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import TimeControl from '@/utils/time-control'
import Config from '@/utils/config'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: Config.baseUrl(), // process.env.VUE_APP_API_BASE_URL,
  timeout: 60000 // 请求超时时间
})
const errorMessage = (message) => {
  ElMessage.error(message)
}
// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    const status = error.response.status
    if (status === 401) {
      store.dispatch("Logout")
      errorMessage(data.message)
      router.push({path: "/login"})
    } else {
      errorMessage(data.message || 'Network Error.')
    }
  } else {
    errorMessage(error.message || 'Network Error.')
  }
  return Promise.reject(error)
}

request.interceptors.request.use(config => {
  const token = store.getters.token
  // 如果 token 存在
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  config.headers.Device = "WEB"
  config.headers.Lang = store.getters.lang || Config.localeLang()
  config.headers.TimeZone = TimeControl.getTimeZone()
  config.headers.isChina = Config.headers().isChina
  config.headers.Accept = Config.headers().accept // `application/vnd.tutoredin.v1+json`
  return config
}, errorHandler)

request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

export default request