import { getStorage, setStorage, removeStorage } from '@/utils/index.js'
import store from '@/store'
import AuthApi from '@/api/auth'
import StudentApi from '@/api/student'
import TeacherApi from '@/api/teacher'
import TimeControl from '@/utils/time-control'

const user = {
  state: {
    token: '',
    expires_in: 0,
    userType: null,
    userInfo: null,
    session_key: null
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_EXPIRES_IN: (state, expires_in) => {
      state.expires_in = expires_in * 60 * 1000
    },
    SET_USER_TYPE: (state, type) => {
      state.userType = type
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_AUTH_SESSION_KEY: (state, data) => {
      state.session_key = data
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        AuthApi.login(userInfo).then(response => {
          commit('SET_TOKEN', response.access_token)
          commit('SET_EXPIRES_IN', response.expires_in)
          if(!!response.user_type) {
            commit('SET_USER_TYPE', response.user_type)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    ShortLogin ({ commit }, params) {
      return new Promise((resolve, reject) => {
        AuthApi.short(params).then(response => {
          commit('SET_TOKEN', response.access_token)
          commit('SET_EXPIRES_IN', response.expires_in)
          if(!!response.user_type) {
            commit('SET_USER_TYPE', response.user_type)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    SetTokenLogin({ commit }, response) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', response.access_token)
        commit('SET_EXPIRES_IN', response.expires_in)
        if(!!response.user_type) {
          commit('SET_USER_TYPE', response.user_type)
        }
        resolve()
      }).catch(error => {
        reject(error)
      });
    },

    // 获取用户信息
    GetUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        if (store.getters.userType == 2) {
          store.dispatch("GetTeacherInfo")
        } else {
          store.dispatch("GetStudentInfo")
        }
      })
    },

    // 学生
    GetStudentInfo({ commit }) {
      return new Promise((resolve, reject) => {
        StudentApi.info().then(response => {
          const data = response.data
          commit('SET_USER_INFO', data)
          TimeControl.setTimezone();
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 老师
    GetTeacherInfo({ commit }) {
      return new Promise((resolve, reject) => {
        TeacherApi.info().then(response => {
          const data = response.data
          commit('SET_USER_INFO', data)
          TimeControl.setTimezone();
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        commit('SET_USER_TYPE', 0)
        commit('SET_USER_INFO', '')
        removeStorage('__TUTOREDIN__')
        resolve()       
      })
    }

  }
}

export default user