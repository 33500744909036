/**
 * 配置
 */
export default class Config {

	// 获取基础地址
	static baseUrl() {
		if(process.env.NODE_ENV == 'development') {
			return process.env.VUE_APP_API_BASE_URL_DEV;
		}
		return process.env.VUE_APP_API_BASE_URL_PRO;
	}
	
	// 判断是否是Tutoredin项目
	static isTutoredin() {
		if(process.env.VUE_APP_PROJECT == 'Tutoredin') {
			return true;
		}
		return false;
	}

	// isChineseBigBang
	static isChineseBigBang() {
		if(process.env.VUE_APP_PROJECT == 'ChineseBigBang') {
			return true;
		}
		return false;
	}

	// 默认语言 localeLang
	static localeLang(lang) {
		let name = null
		if(this.isTutoredin()) {
			name = !!lang && lang == 'zh-cn' ? 'zh-cn' : 'en';
		} else {
			name = !!lang && lang == 'zh-cn' ? 'zh-cn' : 'ru';
		}
		return name;
	}

	// 请求数据头信息
	static headers() {
		let obj = {};
		if(this.isTutoredin()) {
			obj.accept = `application/vnd.tutoredin.v1+json`;
		} else {
			obj.accept = `application/vnd.chinesebigbang.v1+json`;
		}
		obj.isChina = this.website('isGuessChina');

		return obj;
	}

	// 网站基本信息
	static website(name) {
		let origin = window.location.origin;
		let obj = {};
		obj.title = process.env.VUE_APP_TITLE;
		if(this.isTutoredin()) {
			obj.title = 'Tutoredin';
			obj.beian = '京ICP备2021015873号-1';
			if (origin.indexOf("tutoredin.com") != -1) {
				obj.beian = '京ICP备2021015873号-3';
				obj.gongan = '京公网安备11010102007105';
			}
		} else if(this.isChineseBigBang()) {
			obj.title = 'ChineseBigBang';
		}
		let language = navigator.language.toLowerCase();
		
		obj.isGuessChina = 0;
		if(language != 'zh-cn') {
			obj.beian = null;
		} else {
			obj.isGuessChina = 1;
		}
		return name ? obj[name] : obj;
	}
}