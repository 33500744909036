import { getStorage, setStorage, removeStorage } from '@/utils/index.js'
import store from '@/store'
import SubjectApi from '@/api/subject'

const subject = {
  state: {
    subject_data: [],
    select_subject: {}
  },

  mutations: {
    SET_SUBJECT_DATA: (state, data) => {
      state.subject_data = data
    },
    SET_SELECT_SUBJECT: (state, data) => {
      state.select_subject = data
    }
  },

  actions: {
    // 获取课程数据
    GetSubjectData ({ commit }) {
      return new Promise((resolve, reject) => {
        SubjectApi.index().then(response => {
          const data = response.data
          commit('SET_SUBJECT_DATA', data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default subject