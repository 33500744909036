import { getStorage, setStorage, removeStorage } from '@/utils/index.js'
import store from '@/store'
import CommonApi from '@/api/common'
import Config from '@/utils/config'

const app = {
  state: {
  	website: {},
    lang: Config.localeLang() || 'en'
  },
  mutations: {
    SET_SITE_DATA: (state, data) => {
    	state.website = data
    },
    SET_LANG: (state, data) => {
      state.lang = data
    }
  },
  actions: {
  	GetWebSite ({ commit }) {
  		return new Promise((resolve, reject) => {
        CommonApi.website().then(response => {
          commit('SET_SITE_DATA', response.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
  	},
    setLang({ commit }, lang) {
      return new Promise((resolve, reject) => {
        commit('SET_LANG', lang)
        resolve()
      })
    }
  }
}

export default app