import store from '@/store'
import moment from 'moment'
import timezone from 'moment-timezone'
import Config from '@/utils/config'

// 暂时先支持中国时区与英国时区；后期再处理全球时区问题；
export default class TimeControl {
	// 获取当前的年
	static getFullYear() {
		return moment(new Date).year()
	}

	static setTimezone() {
		let zoneFinal = null;
		if(!!store.getters.userInfo) {
			zoneFinal = store.getters.userInfo.timezone;
		}
		if(!!zoneFinal) {
			// moment.tz.setDefault(zoneFinal == 1 ? 'Asia/Shanghai' : 'Europe/London');
			moment.tz.setDefault(zoneFinal);
		}
		return;
	}

	// static zoneFinal() {
	// 	let utcOffset = moment().utcOffset(); // 时差，单位分钟
	// 	let zone = 2;
	// 	if(utcOffset == 480) {
	// 		zone = 1;
	// 	}
	// 	return zone;
	// }

	static zone() {
		return this.getTimeZone()
	}

	static zoneName() {
		let zone = this.zone();
		if(zone == 'Asia/Shanghai') {
			return "CH"
		} else if(zone == 'Europe/London') {
			return "EN"
		}
	}

	static zoneNameString() {
		let zone = this.zone();
		if(zone == 'Asia/Shanghai') {
			return "中国时间"
		} else if(zone == 'Europe/London') {
			return "英国时间"
		}
	}

	/**
	 * 定义 format 来返回具体格式，不存在，则按原始返回
	 * format 
	 * YMD -> 2022-06-02
	 * TMD_STR -> Sat 25th Jun 2022
	 * Hm -> 12:30
	 * Hms -> 12:30:00
	 */
	static TimeFormat(format) {
		let tz = this.getTimeZone('number')
		if(format == 'YMD') {
			return tz == 1 ? "YYYY-MM-DD" : "DD/MM/YYYY";
		} else if(format == 'Hm') {
			return "HH:mm"
		} else if(format == 'Hmi') {
			return "HH:mm:ss";
		} else if(format == 'TMD_STR') {
			return tz == 1 ? "YYYY-MM-DD" : "ddd Do MMM YYYY"
		} else if(format == 'COURSE_FORMAT') {
			return tz == 1 ? "YYYY/MM/DD 周d" : "ddd Do MMM YYYY"
		} else {
			return tz == 1 ? "YYYY-MM-DD HH:mm" : "llll";
		}
	}

	static getZoneCode(timezone) {
		timezone = !!timezone ? timezone : this.getTimeZone();
		if(timezone == 'Asia/Shanghai') {
			return 1;
		}
		if(timezone == 'Europe/London') {
			return 2;
		}
		// 其它，比如俄罗斯
		return 3;
	}

	// 获取当前时区
	static getTimeZone(format) {
		this.setTimezone();

		let zoneFinal = null;
		if(!!store.getters.userInfo) {
			zoneFinal = store.getters.userInfo.timezone;
		}
		if(!!zoneFinal) {
			if(Config.isTutoredin()) {
				return zoneFinal == "Asia/Shanghai" ? zoneFinal : "Europe/London";
			}
			return zoneFinal;
		}
		
		// 获取当前时区，以后再用
		let tz = moment.tz.guess(true);
		if(Config.isTutoredin() && tz != "Asia/Shanghai") {
			return "Europe/London";
		}
		return tz;
		
		// format = !!format && format == 'number' ? 'number' : 'string'
		// let utcOffset = moment().utcOffset(); // 时差，单位分钟
		// let zone = 2;
		// if(utcOffset == 480) {
		// 	zone = 1;
		// }
		
		// if(format == 'number') {
		// 	return zone == 1 ? 1 : 2;
		// }
		// return zone == 1 ? 'Asia/Shanghai' : 'Europe/London';
		// if(utcOffset == 480) {
		// 	return format == 'number' ? 1 : 'Asia/Shanghai';
		// }
		// return format == 'number' ? 2 : 'Europe/London';
	}

	static getTimeStamp(datetime) {
		if(!/^\d+$/.test(datetime)) {
			return datetime;
		}
		if(/^\d{10}$/.test(datetime)) {
			return datetime * 1000;
		}
		return datetime;
	}

	// 获取加时间后的格式化时间
	static getFormatTimeAdd(time, addSecond, format, zone) {
		let timestamp = (this.getUnixTime(time) + addSecond) * 1000
		let timezone = !!zone ? zone : this.getTimeZone();
		if(!!format) {
			return moment.tz(timestamp, timezone).format(format)
		}
		return moment(timestamp).format()
	}

	// 获取时间戳
	static getUnixTime(time) {
		let timestamp = this.getTimeStamp(time)
		return moment(timestamp).unix()
	}

	// 获取时间显示格式
	static getFormatTime(time, format, zone) {
		let timestamp = this.getTimeStamp(time)
		let timezone = !!zone ? zone : this.getTimeZone();
		if(!!format) {
			return moment.tz(timestamp, timezone).format(format)
		}
		format = this.TimeFormat("COURSE_FORMAT");
		let new_timestamp = moment.tz(timestamp, timezone).format(format)
		new_timestamp = new_timestamp.replace("周0", "周日")
		new_timestamp = new_timestamp.replace("周1", "周一")
		new_timestamp = new_timestamp.replace("周2", "周二")
		new_timestamp = new_timestamp.replace("周3", "周三")
		new_timestamp = new_timestamp.replace("周4", "周四")
		new_timestamp = new_timestamp.replace("周5", "周五")
		new_timestamp = new_timestamp.replace("周6", "周六")
		return new_timestamp
	}

	// 获取中国时间
	static getChinaTime(time, format) {
		let timestamp = this.getTimeStamp(time)
		format = !!format ? format : this.TimeFormat()
		if(!!format) {
			return moment.tz(timestamp, "Asia/Shanghai").format(format)
		}
		return moment.tz(timestamp, "Asia/Shanghai").format()
	}

	// 获取英国时间
	static getLondonTime(time, format) {
		let timestamp = this.getTimeStamp(time)
		format = !!format ? format : this.TimeFormat()
		if(!!format) {
			return moment.tz(timestamp, "Europe/London").format(format)
		}
		return moment.tz(timestamp, "Europe/London").format()
	}
	
	// 时间判断，今天、昨天、明天、周一、周二、周三、周四、周五、周六、周日
	static handleTimeDetail(time, zone) {
		let timestamp = this.getTimeStamp(time)
		let timezone = !!zone ? zone : this.getTimeZone();
		let current_time = (new Date).getTime()
		let yesterday = this.getFormatTime(current_time - 86400*1000, "YYYYMMDD", timezone)
		let today = this.getFormatTime(current_time, "YYYYMMDD", timezone)
		let tomorrow = this.getFormatTime(current_time + 86400*1000, "YYYYMMDD", timezone)
		
		let time_format = this.getFormatTime(time, "YYYYMMDD", timezone)
		
		let res = {name: "", day: this.getFormatTime(time, "MM-DD", timezone)}
		let format = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
		if(time_format == yesterday) {
			res.name = "昨天"
		} else if(time_format == today) {
			res.name = "今天"
		} else if(time_format == tomorrow) {
			res.name = "明天"
		} else {
			let fm = this.getFormatTime(time, "d", timezone)
			res.name = format[fm]
		}
		return res
	}
}