/**
 * 与学生有关的接口
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class StudentApi {
    // 学生列表
    static index(params) {
        return request({
            url: '/student/index',
            method: 'post',
			data: params
        })
    }

    // 个人信息
    static info(params) {
        return request({
            url: '/student/info',
            method: 'post',
			data: params
        })
    }

    // 个人信息
    static profile(params) {
        return request({
            url: '/student/profile',
            method: 'post',
            data: params
        })
    }

    // 修改个人资料
    static store(params) {
        return request({
            url: '/student/store',
            method: 'post',
			data: params
        })
    }

    // 优惠卷
    static coupon(params) {
        return request({
            url: '/student/coupon',
            method: 'post',
			data: params
        })
    }
	
	static couponExchange(params) {
		return request({
			url: '/student/coupon-exchange',
			method: 'post',
			data: params
		})
	}

    // 我的收藏
    static collect(params) {
        return request({
            url: '/student/collect',
            method: 'post',
			data: params
        })
    }

    // 所有学生列表
    static list(params) {
        return request({
            url: '/student/list',
            method: 'post',
			data: params
        })
    }
    
    // 授权获取用户信息
    static weUserInfo(params) {
        return request({
            url: '/student/weUserInfo',
            method: 'post',
            data: params
        })
    }
}