<template>
	<el-container class="is-vertical">
		<Header ref="header" />
		<el-main class="tu-main" :style="{ minHeight: (clientHeight - 350) + 'px' }">
			<div v-if="isLogin && userType==2 && userInfo.is_pass != 1" class="tu-steps-class">
				<el-steps :active="stepActive" align-center>
					<el-step title="Create profile" @click="goTo(1)"></el-step>
					<el-step title="Set availability" @click="goTo(2)"></el-step>
					<el-step title="Account details" @click="goTo(3)"></el-step>
				</el-steps>
			</div>
			<router-view />
		</el-main>
		<Footer ref="footer" />
	</el-container>
	<el-backtop />
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { ElNotification } from 'element-plus'
import MessageApi from '@/api/message'
import { checkToken } from '@/utils'

export default {
	name: "BasicLayout",
	components: {
		Header,
		Footer
	},
	data() {
		return {
			clientHeight: ''
		}
	},
	created () {
		this.clientHeight = document.documentElement.clientHeight
	},
	mounted() {
		// this.$store.state.timer = setInterval(this.handleNotification, 15000);
		this.handleNotification();
		this.initStoreData();
	},
	computed: {
		...mapGetters([
			'isLogin', 'userInfo', 'userType', 'isStudent', 'isTeacher'
		]),
		stepActive() {
			if(this.isTeacher && this.userInfo.is_pass != 1) {
				if(this.userInfo.isPass == 0) {
					return 3;
				}
				return this.userInfo.isPass - 1;
			}
			return 3;
		}
	},
	methods: {
		handleNotification() {
			return ;//先关闭吧！
			if(checkToken() === false) {
				return ;
			}
			if(this.$store.state.timer) {
				clearTimeout(this.$store.state.timer);
			}
			let that = this;
			MessageApi.notification().then(res => {
				if(!!res.data && !!res.data.title) {
					let desc = `<span>${res.data.desc}</span> <a href="javascript:;">${this.$t('Click to view')}</a>`;
					ElNotification({
						title: res.data.title,
						dangerouslyUseHTMLString: true,
						message: desc,
						type: 'info',
						duration: 5500,
						onClick: function() {
							if(!!res.data.uri) {
								let params = [res.data.message_id];
								MessageApi.readed(params).then(res => {});
								that.$router.push(res.data.uri);
							}
						}
					})
				}
				this.$store.state.timer = setTimeout(this.handleNotification, 15000);
			})
		},
		initStoreData() {
			if(this.isLogin) {
				if(this.isTeacher) {
					this.$store.dispatch('GetMyStudentList')
				} else {
					this.$store.dispatch('GetMyTeacherList')
				}
				this.$store.dispatch('GetSubjectData')
			}
		},
		goTo(value) {
			value = value - 1;
			if(this.stepActive >= value) {
				if(value == '0') {
					this.$router.push({ path: "/tu/information" });
				} else if(value == '1') {
					this.$router.push({ path: "/tu/availability" });
				} else {
					this.$router.push({ path: "/tu/account" });
				}
			}
		}
	},
	beforeUnmount() {
		if(!!this.$store.state.timer) {
			clearInterval(this.$store.state.timer);
			this.$store.state.timer = null;
		}
	}
}
</script>
<style lang="less">
.el-main.tu-main {
	margin: 0 auto;
	margin-top: 61px;
	width: var(--tu-main-width);
}
.tu-main {
	.tu-steps-class {
		padding: 22px 0;

		.el-step {
			.el-step__title {
				cursor: pointer;
			}
		}
	}
}
</style>