/**
 * 站内消息
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class MessageApi {
    // 消息列表
    static index(params) {
        return request({
            url: '/message/index',
            method: 'post',
			data: params
        })
    }

    // 消息已读
    static readed(params) {
        return request({
            url: '/message/readed',
            method: 'post',
			data: params
        })
    }

    // 消息通知
    static notification(params) {
        return request({
            url: '/message/notification',
            method: 'post',
			data: params
        })
    }
}