import { getStorage, setStorage, removeStorage } from '@/utils/index.js'
import store from '@/store'

const order = {
  state: {
  	yforder: { id: 0, time: 0 }
  },
  mutations: {
    SET_YF_ORDER_DATA: (state, data) => {
    	state.yforder = data
    }
  },
  actions: {
  	SetYfOrderData ({ commit }, data) {
  		return new Promise((resolve, reject) => {
        commit('SET_YF_ORDER_DATA', data)
      })
  	}
  }
}

export default order