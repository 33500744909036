import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

import app from './modules/app'
import user from './modules/user'
import student from './modules/student'
import teacher from './modules/teacher'
import subject from './modules/subject'
import faqs from './modules/faqs'
import order from './modules/order'

import getters from './getters'

const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    timer: null
  },
  mutations: {},
  actions: {},
  modules: {
    app,
    user,
    student,
    teacher,
    subject,
    faqs,
    order
  },
  getters,
  plugins: [createPersistedState({
    key: '__ONLINE_EDUCATION__',
    paths: ['app', 'user', 'faqs', 'order', 'subject', 'student', 'teacher'],
    storage: {
      getItem: function(key) {
        try {
          return ls.get(key);
        } catch(err) {}
      },
      setItem: function (key, value) {
        try {
          return ls.set(key, value);
        } catch(err) {}
      },
      removeItem: function (key) {
        try {
          return ls.remove(key);
        } catch(err) {}
      },
    }
  })]
})
