/**
 * Faqs
 * 2021-12-23
 * author DuZeFang
 */
import request from '@/utils/request'

export default class FaqsApi {
    // 反馈列表
    static index(params) {
        return request({
            url: '/faqs/index',
            method: 'post',
			data: params
        })
    }

    // 反馈详情
    static info(id, params) {
        return request({
            url: `/faqs/info/${id}`,
            method: 'post',
            data: params
        })
    }

    // 反馈分类
    static category(params) {
        return request({
            url: '/faqs/category',
            method: 'post',
            data: params
        })
    }
}