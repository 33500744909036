import BasicLayout from '@/layouts'
// auth 开头路由会放到授权登陆中，不可在路由中使用

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
    meta: { title: "home", index: 'home' }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About'),
    meta: { title: 'about', index: 'about' }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard'),
    meta: { title: 'dashboard', index: 'dashboard' }
  },
  {
    path: '/work',
    name: 'work',
    component: () => import(/* webpackChunkName: "work" */ '@/views/work'),
    meta: { title: 'work', index: 'work' }
  },
  {
    path: '/work/:id',
    name: 'work-detail',
    component: () => import(/* webpackChunkName: "work-detail" */ '@/views/work/detail'),
    meta: { title: 'work-detail', index: 'work' }
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */ '@/views/chat'),
    meta: { title: 'chat', index: 'chat' }
  },
  {
    path: '/chat/:id',
    name: 'chat-content',
    component: () => import(/* webpackChunkName: "chat-content" */ '@/views/chat/content'),
    meta: { title: 'chat-content', index: 'chat' }
  },
  {
    path: '/cost',
    name: 'cost',
    component: () => import(/* webpackChunkName: "cost" */ '@/views/cost'),
    meta: { title: 'cost', index: 'cost' }
  },
  {
    path: '/message',
    name: 'message',
    component: () => import(/* webpackChunkName: "message" */ '@/views/message'),
    meta: { title: 'message', index: 'personal' }
  },
  {
    path: '/st/home',
    name: 'st-home',
    component: () => import(/* webpackChunkName: "st-home" */ '@/views/student'),
    meta: { title: 'st-home', index: 'personal' }
  },
  {
    path: '/st/information',
    name: 'st-information',
    component: () => import(/* webpackChunkName: "st-information" */ '@/views/student/information'),
    meta: { title: 'st-information', index: 'personal' }
  },
  {
    path: '/tu/home',
    name: 'tu-home',
    component: () => import(/* webpackChunkName: "tu-home" */ '@/views/teacher'),
    meta: { title: 'tu-home', index: 'personal' }
  },
  {
    path: '/tu/holiday',
    name: 'tu-holiday',
    component: () => import(/* webpackChunkName: "tu-holiday" */ '@/views/teacher/holiday'),
    meta: { title: 'holiday', index: 'personal' }
  },
  {
    path: '/tu/information',
    name: 'tu-information',
    component: () => import(/* webpackChunkName: "tu-information" */ '@/views/teacher/information'),
    meta: { title: 'personal-information', index: 'personal' }
  },
  {
    path: '/tu/account',
    name: 'tu-account',
    component: () => import(/* webpackChunkName: "tu-account" */ '@/views/teacher/account'),
    meta: { title: 'account', index: 'account' }
  },
  {
    path: '/tu/availability',
    name: 'tu-availability',
    component: () => import(/* webpackChunkName: "tu-availability" */ '@/views/teacher/availability'),
    meta: { title: 'availability', index: 'availability' }
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import(/* webpackChunkName: "notification" */ '@/views/notification'),
    meta: { title: 'notification', index: 'personal' }
  },
  {
    path: '/course',
    name: 'course',
    component: () => import(/* webpackChunkName: "course" */ '@/views/course'),
    meta: { title: 'course', index: 'course' }
  },
  {
    path: '/course/pay',
    name: 'pay',
    component: () => import(/* webpackChunkName: "pay" */ '@/views/course/pay'),
    meta: { title: 'pay', index: 'pay' }
  },
  {
    path: '/course/:id',
    name: 'course-detail',
    component: () => import(/* webpackChunkName: "course-detail" */ '@/views/course/detail'),
    meta: { title: 'course-detail', index: 'course' }
  },
  {
    path: '/student',
    name: 'student',
    component: () => import(/* webpackChunkName: "student" */ '@/views/student/list'),
    meta: { title: 'student', index: 'student' }
  },
  {
    path: '/teacher',
    name: 'teacher',
    component: () => import(/* webpackChunkName: "teacher" */ '@/views/teacher/list'),
    meta: { title: 'teacher', index: 'teacher' }
  },
  {
    path: '/teacher/:id',
    name: 'teacher-detail',
    component: () => import(/* webpackChunkName: "teacher-detail" */ '@/views/teacher/detail'),
    meta: { title: 'teacher-detail', index: 'find-a-tutor' }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "teacher-detail" */ '@/views/course/order'),
    meta: { title: 'order', index: 'order' }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/auth/password'),
    meta: { title: 'change-password', index: 'personal' }
  },
  {
    path: '/find',
    name: 'find',
    component: () => import(/* webpackChunkName: "find-a-tutor" */ '@/views/teacher/find'),
    meta: { title: 'tutor', index: 'find-a-tutor' }
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "faqs" */ '@/views/faqs/index'),
    meta: { title: 'faqs', index: 'faqs' }
  },
  {
    path: '/faqs/:id',
    name: 'faqs-detail',
    component: () => import(/* webpackChunkName: "faqs-detail" */ '@/views/faqs/detail'),
    meta: { title: 'faqs-detail', index: 'faqs-detail' }
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "faqs-detail" */ '@/views/contact-us'),
    meta: { title: 'contact-us', index: 'contact-us' }
  }
]

export default routes