import { getStorage, setStorage, removeStorage } from '@/utils/index.js'
import store from '@/store'
import TeacherApi from '@/api/teacher'

const teacher = {
  state: {
    teacher_list: [],
    teacher_info: []
  },

  mutations: {
    SET_MY_TEACHER: (state, data) => {
      state.teacher_list = data
    },
    SET_TEACHER_INFO: (state, row, uid) => {
      state.teacher_info[uid] = row
    }
  },

  actions: {
    GetTeacherInfo ({ commit }, uid) {
      return new Promise((resolve, reject) => {
        let params = uid ? { uid: uid } : {}
        TeacherApi.info( params ).then(response => {
          const data = response.data
          commit('SET_TEACHER_INFO', data, uid)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取我的老师
    GetMyTeacherList ({ commit }) {
      return new Promise((resolve, reject) => {
        TeacherApi.index( { isall: 1 } ).then(response => {
          const data = response.data
          commit('SET_MY_TEACHER', data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default teacher