import { getStorage, setStorage, removeStorage } from '@/utils/index.js'
import store from '@/store'
import StudentApi from '@/api/student'

const student = {
  state: {
    student_list: [],
    student_profile: []
  },

  mutations: {
    SET_MY_STUDENT: (state, data) => {
      state.student_list = data;
    },
    SET_STUDENT_PROFILE: (state, data) => {
      state.student_profile = data;
    }
  },

  actions: {
    // 获取我的学生
    GetMyStudentList ({ commit }) {
      return new Promise((resolve, reject) => {
        StudentApi.index( { isall: 1 } ).then(response => {
          const data = response.data
          commit('SET_MY_STUDENT', data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default student