/**
 * 平台通用接口
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class CommonApi {
    // 用户上传接口
    static upload(params) {
        return request({
            url: '/common/upload',
            method: 'post',
			data: params
        })
    }

    static uploadSplit(params, headers) {
        return request({
            url: '/common/upload-split',
            method: 'post',
            headers: headers,
            data: params
        })
    }

    // 用户修改密码接口
    static uppasswd(params) {
        return request({
            url: '/common/uppasswd',
            method: 'post',
			data: params
        })
    }

    // 验证码
    static captcha(params) {
        return request({
            url: '/captcha',
            method: 'post',
			data: params
        })
    }

    // 用户在线反馈
    static feedback(params) {
        return request({
            url: '/feedback',
            method: 'post',
			data: params
        })
    }

    // 收藏或取消
    static collect(id, params) {
        return request({
            url: `/common/collect/${id}`,
            method: 'post',
            data: params
        })
    }

    // 重发激活邮件
    static activation(params) {
        return request({
            url: '/common/activation',
            method: 'post',
            data: params
        })
    }

    // 平台配置
    static website(params) {
        return request({
            url: '/website',
            method: 'post',
            data: params
        })
    }
}