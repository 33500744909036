/**
 * 关于平台授权方面的接口
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class AuthApi {
	// 登录
	static login(params) {
		return request({
			url: '/auth/login',
			method: 'post',
			data: params
		})
	}

	// 退出
	static logout(params) {
		return request({
			url: '/auth/logout',
			method: 'post',
			data: params
		})
	}

	// Token刷新
	static refresh(params) {
		return request({
			url: '/auth/refresh',
			method: 'post',
			data: params
		})
	}

	// 注册
	static register(params) {
		return request({
			url: '/auth/register',
			method: 'post',
			data: params
		})
	}

	// 忘记密码
	static forgot(params) {
		return request({
			url: '/auth/forgot',
			method: 'post',
			data: params
		})
	}

	// 忘记密码回调验证
	static forgot_verify(params) {
		return request({
			url: '/auth/forgot_verify',
			method: 'post',
			data: params
		})
	}

	// 账号激活
	static register_confirm(params) {
		return request({
			url: '/auth/register_confirm',
			method: 'post',
			data: params
		})
	}

	// 小程序登陆
	static xcx(params) {
		return request({
			url: '/auth/xcx',
			method: 'post',
			data: params
		})
	}

	// 小程序授权
	static mobile(params) {
		return request({
			url: '/auth/mobile',
			method: 'post',
			data: params
		})
	}

	// 短链接授权登录
	static short(params) {
		return request({
			url: '/auth/short',
			method: 'post',
			data: params
		})
	}
}