const getters = {
    isLogin: state => state.user.token ? true : false,
    token: state => state.user.token,
    userInfo: state => state.user.userInfo,
    userType: state => state.user.userType || 0,
    subjectData: state => state.subject.subject_data || [],
    selectSubject: state => state.subject.select_subject || 0,
    faqsMenuData: state => state.faqs.menu || [],
    studentProfile: state => state.student.student_profile || [],
	webSiteData: state => state.app.website,
    isStudent: state => state.user.userType == 2 ? false : true,
    isTeacher: state => state.user.userType == 2 ? true : false,
    studentList: state => state.student.student_list || [],
    teacherList: state => state.teacher.teacher_list || [],
	lang: state => state.app.lang || 'zh-cn'
}

export default getters