/**
 * 与学科有关的接口
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class SubjectApi {
    // 学科数据
    static index(params) {
        return request({
            url: '/subject/index',
            method: 'post',
			data: params
        })
    }
}