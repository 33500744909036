<template>
	<el-header>
		<div class="header-menu">
			<div class="logo">
				Tutoredin
				<div class="bate">Bate</div>
			</div>
			<el-menu 
				:default-active="activeIndex" 
				class="el-menu-demo" 
				mode="horizontal" 
				@select="handleSelect" 
				background-color="var(--el-color-primary)"
				text-color="var(--el-color-white)"
				active-text-color="var(--el-color-primary-light-7)"
				v-if="loading"
			>
				<el-menu-item v-for="item in menu" :index="item.index" :disabled="item.disabled||false">{{ $t(item.name) }}</el-menu-item>
				<el-sub-menu index="0" v-if="isLogin==1 && userInfo" popper-class="el-submenu-demo">
					<template #title>
						<el-avatar v-if="userInfo.avatar_url" :src="userInfo.avatar_url" fit="cover"></el-avatar> 
						<el-avatar v-else>{{ userInfo.slot }}</el-avatar>
						<span style="margin-left: 5px;">{{ userInfo.nickname }}</span>
					</template>
					<!-- <el-menu-item index="TuHome">{{ $t('router.personal-center') }}</el-menu-item> -->
					<!-- <el-menu-item index="SwitchLang">{{ langString }}</el-menu-item> -->
					<el-menu-item index="Logout">{{ $t('router.sign-out') }}</el-menu-item>
				</el-sub-menu>
			</el-menu>
		</div>
	</el-header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { setDocuemntTitle } from '@/utils/index.js'
import { useI18n } from 'vue-i18n'
import Config from '@/utils/config'

export default {
	setup() {
		const { locale } = useI18n()
		function changeLocale(lang) {
			locale.value = lang;
			this.$store.dispatch('setLang', locale.value);
			setDocuemntTitle(this.$route.meta, locale.value);
		}
		return { changeLocale }
	},
	name: 'Header',
	props: {
		msg: String
	},
	data() {
		return {
			loading: true,
			activeIndex: 'home',
			menu: [
				{name: 'router.home', path: '/', index: 'home'},
				{name: 'router.about', path: '/about', index: 'about'},
				{name: 'router.login', path: '/login', index: 'login'},
				{name: 'router.register', path: '/register', index: 'register'}
			],
			teacher_menu: [
				{name: 'router.dashboard', path: '/dashboard', index: 'dashboard'},
				{name: 'router.chat', path: '/chat', index: 'chat'},
				{name: 'router.student', path: '/student', index: 'student'},
				{name: 'router.course', path: '/course', index: 'course'},
				{name: 'router.work', path: '/work', index: 'work'},
				{name: 'router.cost', path: '/cost', index: 'cost'},
				{name: 'router.personal-center', path: '/tu/home', index: 'personal'},
				{name: 'router.account', path: '/tu/account', index: 'account'},
				{name: 'router.availability', path: '/tu/availability', index: 'availability'},
			],
			student_menu: [
				{name: 'router.dashboard', path: '/dashboard', index: 'dashboard'},
				{name: 'router.chat', path: '/chat', index: 'chat'},
				{name: 'router.teacher', path: '/teacher', index: 'teacher'},
				{name: 'router.course', path: '/course', index: 'course'},
				{name: 'router.work', path: '/work', index: 'work'},
				// {1name: "Earnings", path: '/cost', index: 'cost'},
				{name: 'router.personal-center', path: '/st/home', index: 'personal'},
				{name: 'router.find-a-tutor', path: '/find', index: 'find-a-tutor'}
			]
		}
	},
	created() {
		this.activeIndex = this.$route.meta.index || 'home'
		if(this.isLogin) {
			if(!this.userInfo) {
				this.$store.dispatch('GetUserInfo')
			}
			if(!!this.userType) {
				if(this.userType == 2) {
					if(this.userInfo.is_pass != 1) {
						if(this.userInfo.isPass == 0) {
							this.$store.dispatch('GetUserInfo')
						} else {
							this.teacher_menu.forEach(function(value, key){
								const allowMenu = ['/tu/home', '/tu/information', '/tu/availability', '/tu/account'];
								if(allowMenu.indexOf(value.path) == -1) {
									value.disabled = true;
								}
							})
						}
					}
				}
				this.menu = this.userType == 2 ? this.teacher_menu : this.student_menu;
			}
		}
		
	},
	computed: {
		...mapGetters([
			'isLogin', 'userInfo', 'userType', 'lang'
		]),
		// langString() {
		// 	if(this.lang == 'zh-cn') {
		// 		return "英语";
		// 	} else {
		// 		return "Chinese";
		// 	}
		// }
	},
	watch: {
		$route: {
			handler: function(route) {
				this.activeIndex = route.meta.index || 'home';
			},
			immediate: true
		}
	},
	methods: {
		...mapActions({
			Logout: 'Logout', GetUserInfo: 'GetUserInfo'
		}),
		handleSelect(key, keyPath, item) {
			if(key == 'Logout') {
				this.Logout().then(res => {
					this.$router.push({ path: '/' })
				})
			} else if(key == 'TuHome') {
				if(this.userType == 2) {
					this.$router.push({ path: '/tu/home' })
				} else {
					this.$router.push({ path: '/st/home' })
				}
			} else if(key == 'SwitchLang') {
				let lang = this.lang == 'zh-cn' ? Config.localeLang() : 'zh-cn';
				this.changeLocale(lang);
			} else {
				let routePath = '';
				for(let i in this.menu) {
					if(this.menu[i].index == key) {
						routePath = this.menu[i].path;
						break;
					}
				}
				this.$router.push({ path: routePath })
			}
		}
	}
}
</script>
<style lang="less">
header.el-header{
	// padding: 0;
	height: 61px;
	position: fixed;
	z-index: 2002;
	left: 0px;
	right: 0px;
	border-bottom: solid 1px var(--el-color-primary-light-7);
	background: var(--el-color-primary);
	color: #FFF;
	border: unset;

	.header-menu {
		width: var(--tu-main-width);
		margin: 0px auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.el-menu-demo {
			li.el-menu-item {
				height: 61px !important;
			}
			li:not(.is-disabled):hover, li:not(.is-disabled):focus {
				color: var(--el-color-white);
				background-color: var(--el-color-primary-light-1);
			}
		}

		.logo {
			font-size: 35px;
			line-height: 1.7;
			width: 200px;
			font-weight: 600;
			position: relative;

			.bate {
			    font-size: 12px;
			    position: absolute;
			    top: 10px;
			    right: 5px;
			    font-weight: bolder;
			    color: #F56C6C;
			}
		}

		ul {
			width: 1199px;
			justify-content: flex-end;
			border: unset;
		}
	}
}
.el-submenu-demo {
	ul {
		li:not(.is-disabled):hover, li:not(.is-disabled):focus {
			color: var(--el-color-white) !important;
			background-color: var(--el-color-primary-light-1) !important;
		}
	}
}

.el-popper.is-light {
	border: unset !important;
}
</style>