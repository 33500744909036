import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from './i18n'
import 'normalize.css/normalize.css'
import 'font-awesome/css/font-awesome.css'
import 'quill/dist/quill.snow.css'
import '@/global.less'
import VueCropper from 'vue-cropper'; 
import 'vue-cropper/dist/index.css'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(i18n)
app.use(VueCropper)
app.mount('#app')