<template>
  <div id="app">
    <el-config-provider :locale="locale">
      <router-view />
      <!-- :key="this.$route.path" -->
    </el-config-provider>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineComponent, reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n'
import store from '@/store'
import Config from '@/utils/config'
import zhLang from 'element-plus/lib/locale/lang/zh-cn'
import enLang from 'element-plus/lib/locale/lang/en'
import ruLang from 'element-plus/lib/locale/lang/ru'

export default defineComponent({
  name: "app",
  setup() {
    let language = store.getters.lang;
    if(!language) {
      language = navigator.language.toLowerCase();
    }

    // 每次增加新的语言类型，FIX
    const state = reactive({
      locale: language == 'zh-cn' ? zhLang : (Config.localeLang() == 'ru' ? ruLang : enLang)
    })

    if(language) {
      const { locale } = useI18n()
      locale.value = language
    }

    return {
      ...toRefs(state)
    }
  },
  computed: {
    ...mapGetters([
      'lang'
    ])
  },
  watch: {
    lang() {
      this.locale = this.lang == 'zh-cn' ? zhLang : (Config.localeLang() == 'ru' ? ruLang : enLang)
    }
  }
})
</script>
