import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import routes from '@/router/routes'
import { checkLogin } from '@/utils'
import { setDocuemntTitle } from '@/utils/index.js'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'base',
      component: () => import(/* webpackChunkName: "base" */ '@/layouts/index'),
      children: routes
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/auth/login'),
      meta: { title: 'login', index: 'login' }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import(/* webpackChunkName: "register" */ '@/views/auth/register'),
      meta: { title: 'register', index: 'register' }
    },
    {
      path: '/register/confirm',
      name: 'register-confirm',
      component: () => import(/* webpackChunkName: "register" */ '@/views/auth/register-confirm'),
      meta: { title: 'register-confirm', index: 'register-confirm' }
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import(/* webpackChunkName: "forgot" */ '@/views/auth/forgot'),
      meta: { title: 'forgot', index: 'forgot' }
    },
    {
      path: '/z/:short',
      name: 'z',
      component: () => import(/* webpackChunkName: "z" */ '@/views/z/index'),
      meta: { title: '', index: 'z' }
    },
    {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ '@/views/error/404'),
      meta: { title: '404', index: '404' }
    },
    {
      path: '/:pathMatch(.*)',
      redirect: { name: '404' }
    }
  ]
})

router.beforeEach((to, from, next) => {
  NProgress.start();
  setDocuemntTitle(to.meta);
  try {
    if(checkLogin(to, from, next) === false) {
      next({ path: '/login', query: { redirect: to.fullPath }});
    } else {
      next();
    }
  } catch (e) {
    next('/')
  }
});

router.afterEach(() => {
  NProgress.done();
})

export default router
